

export function convertSecondsToTimestamp(seconds) {
  const pad = (num) => String(num).padStart(2, '0');

  const roundedSeconds = Math.round(seconds);

  const hours = Math.floor(roundedSeconds / 3600);
  const minutes = Math.floor((roundedSeconds % 3600) / 60);
  const secs = roundedSeconds % 60;

  if (hours > 0) {
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  } else {
    return `${pad(minutes)}:${pad(secs)}`;
  }
}


export function returnHumanizedDate(incomingDate, includeTime = true) {
  // Create a new Date object from the input date string
  const date = new Date(incomingDate);
  const now = new Date();

  // Calculate the difference in minutes
  const diffInMinutes = Math.floor((now - date) / (1000 * 60));

  // If less than 2 minutes ago, return "just now"
  if (diffInMinutes < 2 && diffInMinutes >= 0) {
    return "just now";
  }

  // If less than 30 minutes ago, return actual minutes ago
  if (diffInMinutes < 30 && diffInMinutes >= 2) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  }

  // Define options for the humanized format
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  // Use Intl.DateTimeFormat to format the date and time
  const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
  const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

  // Check if the date is today
  const isToday = date.toDateString() === now.toDateString();

  // Construct the humanized date string
  let humanizedDate = formattedDate;

  if (isToday) {
    humanizedDate = "Today";
  }

  if (includeTime) {
    humanizedDate += ` at ${formattedTime}`;
  }

  return humanizedDate;
}


export function formatTime(time) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }
};